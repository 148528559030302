import React, {useEffect, useState} from 'react'
import {
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    EditButton,
    Filter,
    List, Loading,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    useListContext
} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles';
import {Redirect} from 'react-router-dom'
import LazyLoad from 'react-lazyload';
import config from "../config";
import InnerImageZoom from "react-inner-image-zoom";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {Typography} from "@material-ui/core";
import {httpService} from "../data/axiosService";
import SuccessMsg from "./Modal";
import {ImageShowService} from "../service/imageShowService";

const ImagePanel = ({record}) => {
    let {srcUrl, cropUrl, userCropUrl,distanceUrl, notes} = record
    const style = {width: 250, height: "fit-content", margin: 15, padding: 5,}

    const styleNotes = {width: 125, margin: 15, padding: 5,}

    const [urlsImg, setBase64Images] = useState([{srcUrl:'',cropUrl:'',userCropUrl:'',distanceUrl:''}]);

    const spanStyle = {textAlign: 'center', margin: 5}
    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState(notes || '');
    const [notificationMsg, setNotificationMsg] = useState(null); // notificationMsg: {msg: string, severity: 'error'/'success'}

    async function handleSave() {
        try {
            const res = await httpService.post(config.backendAPI + '/save_notes', {filename: record.name, notes: text})
            if (res?.notes) {
                showMsg(`Saved notes for ${record.name}`, "success")
            }
        } catch (e) {
            showMsg(`Failed to save notes - ${record.name}`, "error")

        }
        record.notes = text;
        setIsOpen(false);
    };

    const showMsg = (txt, severity) => {
        setNotificationMsg({msg: txt, severity: severity})
        setTimeout(() => setNotificationMsg(null), 5000)
    }
    const toggleDialog = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {


        const headers= ImageShowService.getHeaders()
        const fetchImages = async (imageUrls) => {
            try {
                const promises = imageUrls.map(async (imageUrl) => {
                    const key=Object.keys(imageUrl)[0]
                    const value=Object.values(imageUrl)[0]
                    const response = await fetch(config.backendAPI + '/'+value,{headers:headers});
                    const blob = await response.blob();

                    return new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const base64String = reader.result;
                            resolve({[key]:base64String, title:imageUrl.title});
                        };
                        reader.readAsDataURL(blob);
                    });
                });

                const base64Results = await Promise.all(promises);
                setBase64Images(base64Results);
            } catch (error) {
                console.error('Error fetching or converting images:', error);
            }
        };
        if(distanceUrl){

        fetchImages([{url:srcUrl,title:'Original Image'}, {url:userCropUrl,title:'Cropped and analyzed'}, {url: cropUrl,title:'User edit'},{url:distanceUrl, title:'Calculated Distances'}]);
        }else {
            fetchImages([{url:srcUrl,title:'Original Image'}, {url:userCropUrl,title:'Cropped and analyzed'}, {url: cropUrl,title:'User edit'}]);

        }
        return () => {

        };
    }, []);
    if (urlsImg.length<3){
        return  <Loading/>
    }
    return <LazyLoad>
        <div>
            <div className={"flex-row align-center"}>
                {urlsImg.map((urlImg,index)=>{
                   return <div key={index} className={"flex-column align-center"}>
                        <span style={spanStyle}>{urlImg.title}	</span>
                       <div style={{...style}}>

                            <InnerImageZoom  className="inner-image-zoom" src={urlImg.url||''}/>
                       </div>
                    </div>
                })
                }
                <div className={"flex-column align-center"}>
                    <Button label="Notes" onClick={toggleDialog} color="primary" variant="outlined">notes</Button>
                    <Typography style={{...styleNotes}} aria-label="empty textarea">{record.notes}</Typography>
                </div>

            </div>
        </div>
        <Dialog aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                open={isOpen}
                onClose={toggleDialog}>
            <DialogTitle id="alert-dialog-title"> Add Notes </DialogTitle>
            <DialogContent>
							  <textarea id="status" className="textarea"
                                        value={text}
                                        onChange={(e) => setText(e.target.value)}
                                        placeholder="Enter text..."
                              />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
        <div className={"flex-row align-center"}>
            {notificationMsg && <SuccessMsg msg={notificationMsg.msg} severity={notificationMsg.severity}/>}
        </div>

    </LazyLoad>
};


const useStyles = makeStyles({
    button: {
        fontSize: 15,
        padding: '5px 10px',
        backgroundColor: "#3f51b5",
        color: "#f7f8fb",

        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",

        '&:hover': {
            backgroundColor: "#f7f8fb",
            color: "#3f51b5",


        }

    },
    item: {
        fontSize: 15,
        padding: 0,
        margin: 0


    },
    img: {
        width: 700
    },
    filter: {
        marginTop: 0,

    }


})
const ImageListActions = () => {
    const classes = useStyles()

    return <TopToolbar>

        <CreateButton className={classes.button} basePath={"/images"} resource={'images'}/>

    </TopToolbar>
}

const ImageFilter = (props) => {
    const classes = useStyles()
    return <Filter className={classes.filter}  {...props}>
        <TextInput label={"Name"} source='name' alwaysOn/>
        <SelectInput label={"Miner"} source='miner' choices={config.cm} alwaysOn/>
        <SelectInput label={"Domain"} source='domain' choices={config.gd} alwaysOn/>


    </Filter>
}
const Total = ({data}) => {
    const [perchaSum, setPercha] = useState(0)
    const [boltSum, setBolt] = useState(0)
    useEffect(() => {
        let perchaSum = 0
        let boltSum = 0
        const values = Object.values(data)
        values.forEach((img) => {
            perchaSum += img.percha || 0
            boltSum += img.bolts || 0

        })
        setPercha(perchaSum)
        setBolt(boltSum)
    }, [data])


    const {total} = useListContext();
    return <div><h5 style={{"padding": 5}}>Total Images: {total}, Bolts: {boltSum} , Percha: {perchaSum}  </h5></div>
}

const ImageList = () => {
    const classes = useStyles()
    const [state] = useState({show: true})


    const BulkAction = () => {
        const list = useListContext()
        if (list) {
            let selectedPerchaSum = 0
            let selectedBoltsSum = 0
            list.selectedIds.forEach((id) => {
                const {bolts, percha} = list.data[id]
                selectedBoltsSum += bolts
                selectedPerchaSum += percha ? percha : 0
            });
            return <h5>Bolts: {selectedBoltsSum} Percha: {selectedPerchaSum}</h5>
        } else {
            return ''
        }

    }
    if (state.show) {

        return (
            <div>
                <List title="Analyzed Images"

                      hasShow={false} hasCreate={true} hasList={true} hasEdit={false}
                      basePath={'images'} resource={'images'} sort={{field: 'posted', order: 'DESC'}}
                      pagination={<Total/>} filters={<ImageFilter/>} actions={<ImageListActions/>}
                      bulkActionButtons={<BulkAction/>}>

                    <Datagrid
                        rowClick={'show'}
                        expand={<ImagePanel/>}
                        className={classes.item} size={"small"}>
                        <TextField className={classes.item} source='name'/>
                        <TextField className={classes.item} source='posted'/>
                        <TextField className={classes.item} source='created'/>
                        <NumberField className={classes.item} source='bolts' sortable={false}/>
                        <NumberField className={classes.item} source='boltsModel' sortable={false}/>
                        <NumberField className={classes.item} source='percha' sortable={false}/>
                        <NumberField className={classes.item} source='perchaModel' sortable={false}/>
                        <TextField className={classes.item} source='miner'/>
                        <TextField className={classes.item} source='domain'/>

                        <EditButton label="" title={''}/>
                        <DeleteWithConfirmButton label={''} redirect={'/'} mutationMode={'pessimistic'}/>

                    </Datagrid>
                </List>
            </div>
        )
    } else return <Redirect to={"/error"}/>

}


export default ImageList
