import * as React from 'react';
import {Layout} from 'react-admin';
import MyAppBar from './MyAppBar';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';




const Menu = () => {
	return <div key={'1'}></div>
}
export const Error = () => {


	return <div className={"error flex-column  align-center"}>
		<ErrorOutlineIcon style={{fontSize: 100}}></ErrorOutlineIcon>
		<h1>Something went wrong </h1>
		<span>please contact : XYZzeron</span>
	</div>
}

const MyLayout = props => {

	return <Layout
		{...props}

		error={Error}
		appBar={MyAppBar}
		menu={Menu}

	>

	</Layout>

}

export default MyLayout;
