import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles, useTheme} from '@material-ui/core/styles';

export default function ResponsiveDialog(props) {
	const useStyles = makeStyles ({
		                              button: {
			                              position: "relative",
			                              zIndex: 40,
			                              top: 0,
			                              right: 0,
			                              fontSize: 15,
			                              margin: '0px 10px',
			                              padding: '5px 10px',
			                              backgroundColor: "#3f51b5",
			                              color: "#f7f8fb",
			                              boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
			                              gridColumn: '2/3',

			                              '&:hover': {
				                              backgroundColor: "#f7f8fb",
				                              color: "#3f51b5",


			                              }
		                              }
	                              })
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const handleClickOpen = () => {
		setOpen(true);
	};
const classes=useStyles()
	const handleClose = (ev) => {
		if(ev){
			props.restore()
		}
		setOpen(false);

	};

	return (
		<div>
			<Button className={classes.button} startIcon={	props.children[2]} variant="contained" color="primary" onClick={handleClickOpen}>
				{props.children[0]}
			</Button>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">						{props.children[1]}
				</DialogTitle>

				<DialogActions>
					<Button autoFocus onClick={()=>handleClose(true)} color="primary">
						Yes
					</Button>
					<Button onClick={()=>handleClose(false)} color="primary" autoFocus>
						No
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
