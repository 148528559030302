

export const ImageShowService = {
	addMinMaxToRegions,
	getHeaders
}





function addMinMaxToRegions (regions) {
	return regions.reduce((acc, region) => {
		const {points} = region

		const {type} = region
		let max = {x: 0, y: 0}
		let min = {x: 1, y: 1}
		let minMax = {min, max}
		if (type === "polygon") {
			minMax = points.reduce((acc, point) => {
				const x = point[0]
				const y = point[1]
				if (x < min.x) {
					min.x = x
				}
				if (y < min.y) {
					min.y = y
				}
				if (x > max.x) {
					max.x = x
				}
				if (y > max.y) {
					max.y = y
				}
				return acc
			}, {min, max})
		} else {
			const {x} = region
			const {y} = region
			const {w} = region
			const {h} = region
			// assume x y is the center and width height is from there
			min.x = x
			min.y = y
			max.x = x + w
			max.y = y + h
		}

		acc.push({...region, ...minMax})

		return acc
	}, [])
}



function getHeaders(){
	const token = localStorage.getItem('token');
	let user = localStorage.getItem('user');
	user =JSON.parse(user)
	const options = {
		headers: new Headers({
			'Accept': 'application/json,image/*',
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
			'Access-Control-Expose-Headers': 'Content-Range',
			'Authorization': `Token ${token}@${user.id}`
		}),
	};
	return options.headers
}