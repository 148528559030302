import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom"
import ReactImageAnnotate from "react-image-annotate";
import {makeStyles} from '@material-ui/core/styles';
import CircularIndeterminate from "./Loader";
import {httpService} from "../data/axiosService";
import RestoreIcon from '@material-ui/icons/Restore';
import {Visibility} from '@material-ui/icons';
import ResponsiveDialog from "./Dialog";
import SuccessMsg from "./Modal";
import config from "../config";
import {ImageShowService} from "../service/imageShowService";
import Button from "@material-ui/core/Button";

export default function AnnotateImg ({img, name, regions, restoreAnalysis}) {
	const [isLoading, setIsLoading] = useState ( true)
	const [state, setState] = useState ({ msg: ''})
	const getData = async (data) => {
		try {
			const imageData = {...data.images[0]}
			imageData.regions = ImageShowService.addMinMaxToRegions(imageData.regions)
			const res = await httpService.post (config.backendAPI + '/image_annotate', imageData)

			if (res?.saved) {
				showMsg (`Image ${res.saved} new annotation  successfully saved!`, 'success')
			}
		} catch (e) {
			showMsg (`Image new annotation saving failed - ${e?.response?.data}`, 'error')
		}
	}
	const showMsg = (txt, severity) => {
		setState ({ msg: txt, severity})
		setTimeout (() => setState ({ msg: ""}), 4000)
	}
	const useStyles = makeStyles ({
		                              button: {
			                              position: "relative",
			                              zIndex: 40,
			                              top: 0,
			                              right: 0,
			                              fontSize: 15,
			                              margin: '0px 10px',
			                              padding: '5px 10px',
			                              backgroundColor: "#3f51b5",
			                              color: "#f7f8fb",
			                              boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
			                              gridColumn: '2/3',

			                              '&:hover': {
				                              backgroundColor: "#f7f8fb",
				                              color: "#3f51b5",


			                              }
		                              }
	                              })
	useEffect (() => {
		setIsLoading(false)
		setTimeout (() => setIsLoading ( true), 4000)
		return () => {

		};
	}, [ img]);
	const classes = useStyles ()
	const { msg, severity} = state
	if (isLoading) {
		return <div className={"flex-column align-center"}>
			<h1>Annotate Image</h1>
			<div className={"flex-row"}>

				<ResponsiveDialog restore={restoreAnalysis}>
					<span>Restore  Model Analysis</span>
					<span>Are you sure you want to restore model analysis?</span>
					<RestoreIcon/>

				</ResponsiveDialog>
				<Link to={`/images/${name}/show`}>

					<Button
						startIcon={<Visibility/>}
						className={classes.button} variant="contained"
						color="primary">Show</Button></Link>
			</div>
			<ReactImageAnnotate
				regionClsList={["Bolt","Percha"]}
				enabledTools={["select", "create-polygon","create-box"]}
				showTags
				images={[
					{
						src: img,
						name: name,
						regions: [...regions]

					}

				]}
				onExit={(data) => getData (data)}
			/>
			{msg && <SuccessMsg msg={msg} severity={severity}/>}
		</div>
	}
	else return <div className={"flex-column align-center loader"}>
		<h3 className={"loading"}>Loading</h3>
		<CircularIndeterminate/>
	</div>


}
