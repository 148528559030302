import {HttpError} from "react-admin";

import config from "./config";

export const logoutAsync = () => {
    try {
        // await organizations.deactivate();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Token ${localStorage.getItem('token')}`);
        const request1 = new Request(`${config.authURL}auth/logout?org=`, {
            method: 'POST',
            headers: myHeaders,
        });
        fetch(request1)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(response.statusText);
                }
                return response.json();
            })
            .then((s) => {
                console.log(s);
                return Promise.resolve();
            }).catch((e)=>{
                throw (e)
        }).finally(() => {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            window.location.assign('/#/login')
            return Promise.resolve();
        });
    } catch (error) {
        console.log("error logging out")
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.assign('/#/login')
    }
};


const authProvider = {
    login: ({username, password}) => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const request = new Request(`${config.authURL}auth/login?org=`, {
                method: 'POST',
                body: JSON.stringify({username, password}),
                headers: myHeaders,
            });

            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        return Promise.reject(response.statusText);
                    }
                    return response.json();
                })
                .then(({key}) => {
                    localStorage.setItem('token', key);
                    return new Promise((resolve, reject)=> {
                        const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append("Authorization", `Token ${localStorage.getItem('token')}`);
                        const request1 = new Request(`${config.authURL}users/self?org=`, {
                            method: 'GET',
                            headers: myHeaders,
                        });
                        fetch(request1)
                            .then(response => {
                                if (response.status < 200 || response.status >= 300) {
                                    return reject(response.statusText);
                                }
                                return response.json();
                            })
                            .then((users) => {
                                console.log(users)
                                localStorage.setItem("user", JSON.stringify(users));
                                return resolve();
                            }).catch(() => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('user');
                            return reject(new HttpError("Unauthorized", 401, {
                                message: "users not in system",
                            }));
                        });
                    });
                })
                .catch(() => {
                    localStorage.removeItem('token');
                    return Promise.reject(new HttpError("Unauthorized", 401, {
                        message: "Invalid username or password",
                    }));
                });
        } catch (error) {
            return Promise.reject(new HttpError("Unauthorized", 401, {
                message: "Invalid username or password",
            }));
        }
    },
    logout: () => {
        logoutAsync();
        return Promise.resolve();
    },
    checkError: (e) => {
        console.log('check error' ,e)
        const token=localStorage.getItem("token")
        const user=localStorage.getItem("user")

        if (e?.status === 401 || e?.status === 403||!token||!user) {
            console.error('Auth error removed tokens')
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.href = "#/login";
            return Promise.reject();
        }
        return Promise.resolve()
    },
    checkAuth: () =>
        localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
    getPermissions: () => {
        return Promise.reject(undefined);
    },
    getIdentity: () => {
        const persistedUser = localStorage.getItem("user");
        const user = persistedUser ? JSON.parse(persistedUser) : null;
        if(user)
            return Promise.resolve(user);
        return Promise.reject("relogin")
    },
};

export default authProvider;
