import simpleRestProvider from 'ra-data-simple-rest'
import httpClient from "./httpClient";
import {dateFormat} from "../service/utilservice";
import config from "../config";


// export let currPath= config.PATH
// export let office = process.env.NODE_ENV==='development'?  `https://smartlight.xyzeron.com/service-${currPath}`:window.location.origin+'/service-'+ currPath
export const dataProvider = simpleRestProvider (config.backendAPI, httpClient)

export let myDataProvider;
myDataProvider = {
	...dataProvider,
	getList: async (resource, params) => {
		try {
			let {data} = await dataProvider.getList (resource, params)
			let array = [...data.data]
			if (array.length > 0) {
				const a = array.map ((item,no) => {
					const {results_by_class,model_results_by_class,miner,domain,notes}=item
					const {Bolt,Percha}=results_by_class
					let bolts = Bolt||0
					let percha = Percha||0
					let boltsModel = model_results_by_class["Bolt"]||0
					let perchaModel = model_results_by_class["Percha"]||0
					const created = dateFormat (item.created)
					const posted = dateFormat (item.posted)
					const image = config.backendAPI + '/'+ item.cropUrl
					return {...item, bolts,notes, image, created, posted, percha ,miner,domain,boltsModel,perchaModel}
				})
				data['data'] = a

			}
			if (data) return data
		} catch (e) {
			throw new Error ('Something went wrong - Please contact XYZeron')
		}
	}
};


