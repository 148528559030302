import React, {useEffect, useState} from 'react'
import httpClient from "../data/httpClient";
import {dateFormat} from "../service/utilservice";
import {EditButton, SelectInput, SimpleForm, useNotify} from "react-admin";
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularIndeterminate from "./Loader";
import LazyLoad from 'react-lazyload';
import config from "../config";
import Grid from '@material-ui/core/Grid'
import InnerImageZoom from "react-inner-image-zoom";
import {ImageShowService} from "../service/imageShowService";
import ComputerIcon from "@material-ui/icons/Computer";
import {useForm} from "react-final-form";
import {Container} from "@material-ui/core";

const ImageShow = ({...props}) => {
    const [sent, formSent] = useState(false)
    const [urlsImg, setBase64Images] = useState({srcUrl: '', userUrl: '', distanceUrl: ''});
    const btnStyle = {
        height: 40,
        position: "relative",
        zIndex: 40,
        top: 0,
        right: 0,
        fontSize: 15,
        padding: '5px 10px',
        backgroundColor: "#3f51b5",
        color: "#f7f8fb",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        gridColumn: '1/-1',
        gridRow: '-2/-1',
    }
    const [state, setState] = useState({
        miner: '',
        domain: '',
        loader: true,
        msg: '',
        cropUrl: '',
        srcUrl: '',
        userUrl: '',
        id: ' ',
        posted: '',
        name: '',
        percent: ' ',
        created: 1000,
        proportions: 1,
        originalUserSelection: {}
    })
    const [bolt, setBolt] = useState(0)
    const [percha, setPercha] = useState(0)
    useEffect(() => {
            const id = props.match.params.id

            async function getOne(id) {
                try {
                    const url = config.backendAPI + '/image_show/' + id
                    let res = await httpClient(url)
                    const data = res.json[0]
                    const {
                        domain,
                        miner,
                        percentage,
                        posted,
                        created,
                        name,
                        notes,
                        results_by_class
                    } = data
                    setBolt(results_by_class['Bolt'])
                    setPercha(results_by_class['Percha'])
                    let originalUserSelection = {}
                    if (data?.user_selection) {
                        let {x_end, x_start, y_end, y_start} = data.user_selection
                        originalUserSelection = {
                            height: (y_end - y_start),
                            width: (x_end - x_start),
                            x: x_start,
                            y: y_start,
                            unit: 'px'

                        }

                        setState(prevState => {
                            return {
                                ...prevState,
                                originalUserSelection,
                                notes,
                                posted,
                                created,
                                percentage,
                                name,
                                id,
                                results_by_class,
                                domain,
                                miner

                            }
                        })

                        return data
                    }

                } catch (err) {


                }

            }

            const headers = ImageShowService.getHeaders()
            const fetchImages = async (imageUrls) => {
                try {
                    const promises = imageUrls.map(async (imageUrl) => {
                        const key = Object.keys(imageUrl)[0]
                        const value = Object.values(imageUrl)[0]
                        const response = await fetch(config.backendAPI + '/' + value, {headers: headers});
                        const blob = await response.blob();
                        return new Promise((resolve) => {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const base64String = reader.result;
                                resolve({[key]: base64String});
                            };
                            reader.readAsDataURL(blob);
                        });
                    });

                    const base64ResultsArray = await Promise.all(promises);
                    const base64ResultsObject = Object.assign({}, ...base64ResultsArray);
                    setBase64Images(base64ResultsObject);
                } catch (error) {
                    console.error('Error fetching or converting images:', error);
                }
            };

            getOne(id).then((x) => {
                if (x?.srcUrl && x?.userUrl) {
                    const arrayUrl = [{srcUrl: x.srcUrl}, {userUrl: x.userUrl}]
                    if (x?.distanceUrl) {
                        arrayUrl.push({distanceUrl: x.distanceUrl})
                    }
                    fetchImages(arrayUrl)
                        .then(() => setState((prevState) => {
                                return {...prevState, loader: false}
                            }
                        )).catch(() =>
                        console.error('failed to get images')
                    )
                }

            })

        },
        [state.loader])


    let copyProps = {...props}
    delete copyProps.hasShow
    let {
        loader,
        name,
        posted,
        created,
    } = state

    const createdDate = dateFormat(created)
    const postedDate = dateFormat(posted)
    const notify = useNotify()
    const calculate = () => {
        httpClient(config.backendAPI + '/calc_distance', {
            method: "post",
            body: JSON.stringify({id: state.id})
        }).then(() => {
            notify('Calculation Updated', 'success')
            setState({...state, loader: true})

        }).catch
        (() => {
            notify('Calculation Not Updated', 'warning')
        })
    }
    const sendForm = (f) => {
    const data= {domain:f?.domain||null,miner:f?.miner||null, id: state.id}
    httpClient(config.backendAPI + '/update', {
        method: "post",
        body: JSON.stringify(data)
    }).then(() => {

        notify('Updated', 'success')
            formSent(true)
    }).catch
    (() => {
        notify('Not Updated', 'warning')
    })
}
const ToolBar = (p) => {
    const form = useForm()
    const formState = form.getState()
    const values = formState?.values
    return <>
        <Button color={'primary'} startIcon={<SaveIcon/>} variant={'contained'} style={{marginLeft: 100}}
                onClick={() => sendForm(values || {})}>SAVE</Button>
        {(sent && values?.domain && values?.domain[2] > 2) || (p?.record.domain && p?.record?.domain[2] > 2 && !urlsImg?.distanceUrl) ?
            <Button startIcon={<ComputerIcon/>} style={{marginLeft: 10}} onClick={() => calculate()}
                    color={'primary'} variant={'contained'}>CALCULATE DISTANCES</Button> :
            <Button disabled startIcon={<ComputerIcon/>} style={{marginLeft: 10}} color={'primary'}
                    variant={'contained'}>CALCULATE DISTANCES</Button>
        }
    </>
}
    const SanitizedContainer = ({basePath, ...props}) => {
        return (
            <Container {...props} />
        );
    };
if (loader) return <div className={"flex-column align-center loader"}>
    <h3 className={"loading"}>Loading</h3>
    <CircularIndeterminate/>
</div>
return <div className={"flex-column align-center space-between"}>
    <Grid container spacing={2}>
        <Grid item xs={7}>
            <SimpleForm basePath={'update'} mutationMode={'optimistic'}
                        record={{domain: state.domain, miner: state.miner}} style={{display: 'flex'}}
                        toolbar={<ToolBar/>
                        }


            >
                <SanitizedContainer>
                <Box display={{xs: 'block', sm: 'flex', width: '100%'}}>
                    <Box flex={1}>
                        <SelectInput onChange={() => formSent(false)} style={{marginRight: 8}} allowEmpty={true}
                                     source={'miner'}
                                     choices={config.cm}/>
                    </Box>
                    <Box flex={1}>
                        <SelectInput onChange={() => formSent(false)} style={{marginRight: 8}} allowEmpty={true}
                                     source={'domain'}
                                     choices={config.gd}/>
                    </Box>
                </Box>
            </SanitizedContainer>

            </SimpleForm>
        </Grid>
        <Grid item xs={5} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Link to={"/images/create"}>
                <Button
                    style={{marginTop: 14}}
                    startIcon={<CreateIcon/>} variant="contained" color="primary"
                >
                    Create Another

                </Button></Link>
        </Grid>

    </Grid>

    <div className={"flex-row align-center show-container"}>


        <div className={"show "}>
            <div className={"head"}>
                <h3>  {name} - original</h3>

            </div>
            <div className={"img-container"}>
                <LazyLoad>
                    <InnerImageZoom src={urlsImg.srcUrl || ''}/>
                </LazyLoad>
                <span className={"created"}> Created  at: {createdDate}</span>
            </div>


        </div>
        <div className={"show "}>
            <div className={"head"}>
                <h3>Analyzed</h3>
                <span className={"halite"}> Bolts : {bolt ? bolt : 0} </span>
                <span className={"halite"}> Percha : {percha ? percha : 0}</span>
            </div>

            <div className={"img-container"}>
                <LazyLoad>
                    <InnerImageZoom src={urlsImg.userUrl || ''}/>
                </LazyLoad>

            </div>
            <span className={"posted"}>Posted at: {postedDate}</span>
            <EditButton label="Annotate" style={{...btnStyle}} to={'/images/' + name}/>


        </div>
        {urlsImg.distanceUrl && <div className={"show "}>
            <div className={"head"}>
                <h3>Distance Calculation</h3>
            </div>

            <div className={"img-container"}>
                <LazyLoad>
                    <InnerImageZoom src={urlsImg.distanceUrl || ''}/>
                </LazyLoad>

            </div>


        </div>}


    </div>

</div>

}

export default ImageShow
