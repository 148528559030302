const CONTINUOUS_MINER=[
    'CM-18',
    'CM-20',
    'CM-22',
    'CM-24',
    'CM-26',
    'CM-28',
    'CM-30',
    'CM-32',
    'CM-34',
    'CM-36',
    'CM-38',
    'CM-40',
    'CM-85',
    'CM-3R',
    'CM-5R',
    'CM-6R',
    'CM-11R'
]
const GEOTECHNICAL_DOMAINS=[
    'GD5+',
    'GD5',
    'GD4',
    'GD3',
    'GD2',
    'GD1'


]
const config = {
    authURL: 'https://cvat.xyzeron.com/api/',
    backendAPI: `https://bolts.xyzeron.com/api`,
    // backendAPI: `http://localhost:5000/api`,
    organization: {
        organizationID: null,
        organizationSlug: null,
    },
    origin: '',
    uploadChunkSize: 100,
    removeUnderlyingMaskPixels: false,
    onOrganizationChange: null,
    cm:CONTINUOUS_MINER.map((d)=>{return {id:d,name:d}}),
    gd:GEOTECHNICAL_DOMAINS.map((d)=>{return {id:d,name:d}})
};

export default config;


