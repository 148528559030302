import React, { useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Logo from '../assets/img/xyz-logo-grey.png'
import {Toolbar} from "@material-ui/core";
import Alert from "./Modal.jsx"
import {useLogout,AppBar} from "react-admin";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles ({
	                              title: {
		                              flex: 1,
		                              textOverflow: 'ellipsis',
		                              whiteSpace: 'nowrap',
		                              overflow: 'hidden',
	                              },
	                              spacer: {
		                              flex: 1,
	                              },
	                              bar: {
		                              backgroundColor: '#404145',
		                              color: '#404145',
		                              display: 'flex',
		                              width: '100%',

		                              alignItems: 'space-between',
	                              },
	                              pop: {
		                              padding: 15,
		                              backgroundColor: "black"
	                              },
	                              btn: {
									  '&:hover': {
										  backgroundColor: "rgba(0, 0, 0, 0.2)"
									  },
									  color: "white"
	                              },

	                              icon: {
		                              color: "white",


	                              },
	                              usermenu: {
		                              position: "sticky",
		                              backgroundColor: 'red'
	                              }
                              });


const MyAppBar = () => {
	const [state] = useState ({threshold: 70, msg: ''})
	const classes = useStyles ();
	const logout = useLogout();
	const handleClick = () => logout();
	return (
		<AppBar open={false}  className={classes.bar}>
			<Toolbar  className={classes.bar} disableGutters>
				<a href={`/#`}>
					<div className={"flex-row align-center"}>
						<img alt="logo" src={Logo} style={{width: 50}}/>

						<h3>Computer Vision Image Processing - Bolts</h3></div>
				</a>
				<Button className={classes.btn}
					onClick={handleClick}
				>
					<ExitIcon /> Logout
				</Button>
			</Toolbar>
			{state.msg && <Alert msg={state.msg}/>}
		</AppBar>
	);
};


export default MyAppBar;
