import React, {useEffect, useState} from "react";
import {httpService} from "../data/axiosService";
import AnnotateImg from "./Annotate";
import httpClient from "../data/httpClient";
import SuccessMsg from "./Modal";
import {Redirect} from 'react-router-dom'
import config from "../config";
import {ImageShowService} from "../service/imageShowService";

const ImageAnnotate = (props) => {
	const [base64Image, setBase64Image] = useState(null);
	const [state, setState] = useState ({
		                                    regions: [],
		                                    name: '',
		                                    out: true,
		                                    src: '',
		                                    restore: true,
		                                    msg: '',
		                                    severity: '',
		                                    regionsJsonUrl: ''

	                                    })


	async function getRegions (regions) {

		const res = await httpService.get (config.backendAPI + '/' + regions)
		const myRegions = res.regions
		return myRegions || []

	}

	async function restoreAnalysis () {

		const {name} = state
		try {
			const res = await httpService.post (config.backendAPI + '/restore_image', {filename: name})

			if (res?.restored) {
				showMsg (`${name} annotation successfully restored`, "success")
				window.location.reload(false);
			}
		} catch (e) {
			showMsg (`Model analysis restoration failed - ${e?.response?.data}`, "error")

		}

	}

	const showMsg = (txt, severity) => {
		setState ({...state, msg: txt, severity})
		setTimeout (() => setState ({...state, msg: ""}), 4000)
	}

	useEffect (() => {

		const headers= ImageShowService.getHeaders()
		async function getInfo () {
			try {
				let name = props.match.params.id
				const url = config.backendAPI + '/image_show/' + name
				let res = await httpClient (url)

				const regions = res.json[0]?.regions
				const srcOutput = res.json[0]?.srcOutput
				const myRegions = await getRegions (regions)
				const src= config.backendAPI + '/'+ srcOutput
				setState ({...state,regionsJsonUrl:regions  ,regions: myRegions, src, name})
				const fetchImage = async () => {
					try {
						const response = await fetch(src,{ headers: headers });
						const blob = await response.blob();

						const reader = new FileReader();
						reader.onloadend = () => {
							const base64String = reader.result;
							setBase64Image(base64String);
						};

						reader.readAsDataURL(blob);
					} catch (error) {
						console.error('Error fetching or converting image:', error);
					}
				};

				fetchImage();
			} catch (e) {
				setState ({...state, msg: 'error'})
			}

		}

		getInfo ()

		return () => {

		};
	}, [ props.match.params.id]);
	const {src, regions, name, msg, severity} = state
	if (msg === 'error') return <Redirect to={"/error"}></Redirect>
	else return <div >
		<AnnotateImg img={base64Image} regions={regions} name={name} restoreAnalysis={restoreAnalysis}/>
		<div className={"flex-row align-center"}>
		{msg && <SuccessMsg msg={msg} severity={severity}/>}</div>
	</div>

}
export default ImageAnnotate


