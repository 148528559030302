import React from 'react'
import {Admin, ListGuesser, Resource,Login} from 'react-admin'
import MyLayout from "./components/MyLayout";
import {myDataProvider} from "./data/dataProvider";
import customRoutes from './customRoutes';
import authProvider from './authProvider';
import bc from './assets/img/bc.png'

const MyLoginPage = () => <Login   backgroundImage={bc} />;
function App() {

    return (
        <React.Fragment>
            <Admin
                customRoutes={customRoutes}
                authProvider={authProvider}
                layout={MyLayout}
                loginPage={MyLoginPage}
                dataProvider={myDataProvider}
                // logoutButton={MyLogoutButton}
                 >
                <Resource
                    name='images'
                    list={ListGuesser}
                />

            </Admin>

            <footer className={"flex-column align-center"}  ><span>© All rights reserved to XYZeron.Ltd</span></footer>
        </React.Fragment>
    )
}

export default App
