export function dateFormat(timeStampStart) {

	const date= new Date(timeStampStart)
	let m = (date.getMonth()+1)
	const y = date.getFullYear().toString()
	let d = date.getDate()
	let hours= date.getHours()
	let minutes= date.getMinutes()
	d= d<10 ? 0+d.toString():d
	m= m < 10? 0+m.toString():m
	minutes=minutes<10 ? 0+minutes.toString():minutes
	hours=hours<10 ? 0+hours.toString():hours
	if (date==="Invalid Date") return '-'
	return `${d}/${m}/${y}, ${hours}:${minutes}`
}