import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
	return <MuiAlert  elevation={2} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width:'60%',
		height:50,
		position:"fixed",
		bottom: 80,
		fontSize:15

	},
}));

export default function CustomizedSnackbars({msg,severity}) {

const classes=useStyles()
	return (
		<div className={"flex-column align-center absolute"}>
				<Alert className={classes.root} severity={severity}>
					{msg}
				</Alert></div>
	);
}

