import * as React from "react";
import { Route } from 'react-router-dom';
import ImageShow from "./components/ImageShow";
import ImageList from "./components/ImageList";
import ImageCreate from "./components/ImageCreate";
import ImageAnnotate from "./components/ImageAnnotate";
import {Error} from "./components/MyLayout";

const routes = [
	<Route exact path="/images/:id/show" name={"show"} component={ImageShow} />,
	<Route exact path="/images/create" name={"create"}  component={ImageCreate} />,
	<Route exact path="/images/:id" name={"annotate"}  component={ImageAnnotate} />,
	<Route exact path="/error" name={"error"} component={Error} />,
	<Route exact path="/" name={"images"} component={ImageList} />,
];
export default routes;
