import React, {useEffect, useRef, useState} from 'react'
import ReactCrop from 'react-image-crop';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import camera from '../assets/img/upload-svgrepo-com.svg'
import {httpService} from "../data/axiosService";
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import HelpIcon from '@material-ui/icons/Help';
import CircularIndeterminate from "./Loader";
import SimplePopover from "./PopOver";
import Alert from './Modal.jsx'
import {Redirect} from 'react-router-dom'
import config from "../config";

const ImageCreate = () => {

	const frameRef = useRef ('')
	const refInput = useRef ('')

	const [state, setState] = useState (
		{
			msg: '',
			name: '',
			file: '',
			img: '',
			crop: {
				y:0,x:0,height:0,
				unit: 'px',
				width: 300,
				aspect: 4 / 3
			},
			croppedImg: '',
			croppedImgHash: 0,
			originalHeight: 0,
			originalWidth: 0,
			frameHeight: 400,
			frameWidth: 900,
			proportions: 1,
			loading: false,
			createDate: 0,
			annotateDetails: {},
			percentage: 70,
			stopLoading: false

		})
	useEffect (() => {
	}, [state.croppedImg, state.name])

	const sendForm = async (ev) => {
		ev.preventDefault ()
		setState ({...state, croppedImg: '', loading: true})
		if (state.file) {
			const {proportions} = state
			const {aspect, height, unit, width, x, y} = state.crop
			//adjusting crop data to original image proportions
			const croppingWidth = width * proportions
			const croppingHeight = height * proportions
			const croppingX = x * proportions
			const croppingY = y * proportions
			const cropJson = JSON.stringify ({aspect, croppingWidth, croppingHeight, unit, croppingX, croppingY})
			const formData = new FormData ();
			const {name, createDate, file} = state
			const fileJson = JSON.stringify ({name, createDate, "id": name})
			formData.append (
				"file",
				file,
				name
			);
			formData.append (
				"fileJson",
				fileJson
			);
			formData.append (
				"cropJson",
				cropJson
			);
			try {
				let response = await httpService.post (config.backendAPI + '/images', formData)
				if (response) {

						const {name} = response

					let nameUrl = name
					nameUrl = nameUrl.replace (/\(/g, "")
					nameUrl = nameUrl.replace (/\)/g, "")
					nameUrl = nameUrl.replace (/\s/g, "_")
					const date = Date.now ()
					setState ({...state, name, loading: true})
					setTimeout (() => {
						            setState ({
							                      ...state,
							                      // croppedImg: url || state.img,
							                      croppedImgHash: date,
							                      loading: false,
							                      stopLoading: true,
							                      name: nameUrl

						                      })
					            }
						, 4000)
				}

			} catch (e) {
				showError (`Could not upload image - ${e?.response?.data}`, 'error')
			}
		}
		else return false
	}
	const showError = (txt, severity) => {
		setState ({...state, msg: txt, severity})
		setTimeout (() => setState ({...state, msg: ""}), 8000)
	}
	//receiving file data , creating url and extracting data to state
	const handleImage = ev => {
		const file = ev.target.files[0]
		if (!file) return null

		let fileNameLower = file.name
		fileNameLower = fileNameLower.toLowerCase ()
		if (fileNameLower.endsWith (".jpg")) {
			if (file) {
				let reader = new FileReader ();
				reader.onload = function (e) {
					let img = new Image ()
					img.onload = function () {
						const url = URL.createObjectURL (file)
						const proportions = img.width / state.frameWidth
						setState ({
							          ...state,
							          originalHeight: img.height,
							          originalWidth: img.width,
							          img: url,
							          file,
							          name: file.name,
							          id: file.name,
							          createDate: file.lastModified,
							          formValid: true,
							          proportions: proportions,
							          frameHeight: img.height / proportions,
							          croppedImg: ''
						          })
					}
					img.src = reader.result;
				};
				reader.readAsDataURL (file)
			}
		}
		else {
			showError ("Only jpg files are permitted!")
			return "Error"
		}
	}
	const onImageLoaded = image => {
		console.log('pp')

	};

	const onCropChange = (crop, percentCrop) => {
		setState ({...state, crop});
	}
	const click = () => {
		const btn = refInput.current
		btn.click ()
	}

	const {frameWidth, frameHeight, name, img, loading, crop, msg, stopLoading, severity} = state



	if (stopLoading) return <Redirect to={`/images/${name}/show`}/>
	else if (loading && !stopLoading) return <div className={"flex-column align-center loader"}><h3
		className={"loading"}>Analyzing</h3> <CircularIndeterminate/></div>
	else return <div>
			<form>
				<div className={"flex-row align-center"}>
					<h1>Add an Image </h1>
					<SimplePopover>
						<HelpIcon/>
						<ul className={"instructions"}>
							<li>Choose an Image <b> (jpg only!)</b></li>
							<li>Set cropping area</li>
							<li>Press save</li>
						</ul>
					</SimplePopover>
				</div>


				<div className={"flex-column align-center"}>
					<div className={"cropped flex-column align-center"}>

						<h3>{name}<br/></h3>

						{msg && <Alert msg={msg} severity={severity}/>}
						<input className={"trans"} ref={refInput} type="file" name={"files"}
						       placeholder={"choose picture"}
						       onChange={handleImage}/>
						<div ref={frameRef}>
							<ReactCrop imageStyle={{width: frameWidth, height: frameHeight}} src={img||camera}
							           crop={crop}
							           keepSelection={true}
							           onImageLoaded={onImageLoaded}
							           onChange={onCropChange}/>
						</div>
						<div className={"flex-row align-center"}>

							<Button
								onClick={click}
								variant="contained"
								startIcon={<InsertPhotoIcon/>}
							>
								SELECT IMAGE
							</Button>
							{img !== "" ? <Button

								variant="contained"
								color="primary"
								type="submit"
								onClick={sendForm}
								startIcon={<SaveIcon/>}>Analyze</Button> : ''}</div>
					</div>
				</div>
			</form>
		</div>


}
export default ImageCreate


