import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function CircularIndeterminate() {

	return (
			<CircularProgress disableShrink  size={90}/>
	);
}
